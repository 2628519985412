// @ts-nocheck
import React from 'react';
import { Router } from '@gatsbyjs/reach-router';
import AppLayout from '../components/layout/AppLayout';
import CompareDiff from '../components/CompareDocument/CompareDiff';

const DocumentDiffs = () => {
  return (
    <AppLayout>
      <Router>
        <CompareDiff path="/document-diffs" />
      </Router>
    </AppLayout>
  );
};
export default DocumentDiffs;
