import React, { useEffect, useState } from 'react';

import { pdfjs } from 'react-pdf';
import { Box } from '@mui/material';
import DiffView from './DiffView';
import TOC from '../DocumentPage/TOC';
import { documentService } from '../../services/DocumentService';
import { DocumentDiffResult } from '../../types/RealtaDocument';
import { useLocation } from '@gatsbyjs/reach-router';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const CompareDiff: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const originalDocUuid = queryParams.get('original');
  const updatedDocUuid = queryParams.get('updated');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [diffChanges, setDiffChanges] = useState<DocumentDiffResult | null>(null);

  useEffect(() => {
    const init = async () => {
      if (!originalDocUuid || !updatedDocUuid) {
        return;
      }

      setIsLoading(true);

      const diffResult = await documentService.compareDocuments(originalDocUuid, updatedDocUuid);
      setDiffChanges(diffResult);

      setIsLoading(false);
    };

    init();
  }, []);

  if (!originalDocUuid || !updatedDocUuid) {
    return <Box>You need to add 2 params: original and updated as 2 document ids in url to compare documents</Box>;
  }

  return (
    <Box>
      {isLoading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="fixed"
          top={0}
          left={0}
          width="100vw"
          height="100vh"
          bgcolor="black"
          color="white"
          zIndex={10}
        >
          <h2>Comparing documents...</h2>
        </Box>
      )}
      <TOC documentUuid={originalDocUuid} />
      <Box display="flex" gap={2} height="calc(100vh - 80px)">
        <Box sx={{ overflowY: 'auto', display: 'flex' }}>
          <DiffView diffs={diffChanges?.doc1ChangeMap} />
        </Box>
        <Box sx={{ overflowY: 'auto', display: 'flex' }}>
          <DiffView diffs={diffChanges?.doc2ChangeMap} />
        </Box>
      </Box>
    </Box>
  );
};
export default CompareDiff;
